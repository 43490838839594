<template>
    <LiefengContent>
        <template #title>平均访问次数</template>
        <template #toolsbarRight></template>
        <template #contentArea>
            <div class="card">
                <span>数据来源：微信小程序统计</span>
                <Tooltip content="Here is the prompt text">
                    <Icon color="#336DFF" size="20" type="ios-alert-outline" />
                    <span>数据项解释</span>
                </Tooltip>
                <DatePicker 
                    :clearable="false"
                    format="yyyy-MM-dd" 
                    type="daterange" 
                    placement="bottom-end" 
                    placeholder="Select date" 
                    :value="searchTime"
                    @on-change="getData"
                    style="width: 200px">
                </DatePicker>
            </div>
            <div class="card">
                <div class="card_heard">
                    <div v-show="showType == 'line'">
                        1月25日——4月28日
                        <span>均值8000</span>
                    </div>
                    <Button :icon="btnIcon" @click="changeType">{{ btnName }}</Button>
                </div>
                <div class="card_content">
                    <Veline v-if="showType == 'line'" :data="linedata" :textStyle="textStyle"></Veline>
                    <Table :loading="tableLoading" v-if="showType == 'table'" :columns="columns1" :data="data1"></Table>
                </div>
            </div>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import Veline from "v-charts/lib/line.common"
export default {
    components: {
        LiefengContent,
        LiefengTable,
        Veline,
    },
    data() {
        return {
            showType: "line",
            btnName: "切换为表格",
            btnIcon: "ios-grid",
            tableLoading: false,
            searchTime:'',
            linedata: {
                columns: ["日期", "日访问人数"],
                rows: [
                    {
                        日期: "01/25",
                        日访问人数: "800",
                    },
                    {
                        日期: "01/26",
                        日访问人数: "6000",
                    },
                    {
                        日期: "01/27",
                        日访问人数: "8000",
                    },
                    {
                        日期: "01/28",
                        日访问人数: "10000",
                    },
                    {
                        日期: "01/29",
                        日访问人数: "20000",
                    },
                    {
                        日期: "01/30",
                        日访问人数: "24000",
                    },
                    {
                        日期: "01/31",
                        日访问人数: "10000",
                    },
                    {
                        日期: "02/01",
                        日访问人数: "8000",
                    },
                    {
                        日期: "02/02",
                        日访问人数: "8000",
                    },
                    {
                        日期: "02/03",
                        日访问人数: "3000",
                    },
                    {
                        日期: "02/04",
                        日访问人数: "9000",
                    },
                ],
            },
            textStyle: {
                // fontSize:30
            },
            columns1: [
                {
                    title: "序号",
                    key: "no",
                    align: "center",
                },
                {
                    title: "日期",
                    key: "time",
                    align: "center",
                },
                {
                    title: "日访问人数",
                    key: "num",
                    align: "center",
                },
            ],
            data1: [
                {
                    no: 1,
                    time: "2022-01-22",
                    num: 3000,
                },
                {
                    no: 1,
                    time: "2022-01-22",
                    num: 3000,
                },
                {
                    no: 1,
                    time: "2022-01-22",
                    num: 3000,
                },
                {
                    no: 1,
                    time: "2022-01-22",
                    num: 3000,
                },
                {
                    no: 1,
                    time: "2022-01-22",
                    num: 3000,
                },
                {
                    no: 1,
                    time: "2022-01-22",
                    num: 3000,
                },
                {
                    no: 1,
                    time: "2022-01-22",
                    num: 3000,
                },
                {
                    no: 1,
                    time: "2022-01-22",
                    num: 3000,
                },
                {
                    no: 1,
                    time: "2022-01-22",
                    num: 3000,
                },
                {
                    no: 1,
                    time: "2022-01-22",
                    num: 3000,
                },
                {
                    no: 1,
                    time: "2022-01-22",
                    num: 3000,
                },
                {
                    no: 1,
                    time: "2022-01-22",
                    num: 3000,
                },
                {
                    no: 1,
                    time: "2022-01-22",
                    num: 3000,
                },
                {
                    no: 1,
                    time: "2022-01-22",
                    num: 3000,
                },
            ],
        }
    },
    created(){
        this.$Message.loading({
            content: "获取数据中...",
            duration: 0,
        });
        setTimeout(() => {
            this.$Message.destroy();
        },500)
    },
    methods: {
        changeType() {
            if (this.showType == "line") {
                this.btnName = "切换为趋势图"
                this.showType = "table"
                this.btnIcon = "md-trending-up"
                this.tableLoading = true
                let _this = this
                setTimeout(() => {
                    _this.tableLoading = false
                }, 200)
            } else {
                this.btnName = "切换为表格"
                this.showType = "line"
                this.btnIcon = "ios-grid"
            }
        },
        getData(date){
            console.log(date)
        }
    },
    
    
}
</script>
    
<style scoped lang='less'>